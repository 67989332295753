<template lang="pug">
.login-container.d-flex.align-items-center
  .custom-shape-divider-bottom-1607648200
    svg(data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 120' preserveAspectRatio='none')
      path.shape-fill(d='M1200 120L0 16.48 0 0 1200 0 1200 120z')

  
  b-container
    b-row.justify-content-center
      b-col(cols="6")
        b-card(no-body)
          .p-5
            b-img.mb-5(src="@/assets/logo.png", width="200", center)
            validation-observer(v-slot="{invalid, handleSubmit}")
              b-form(@submit.prevent="handleSubmit(doLogin)")
                b-form-group(label="Correo electrónico")
                  validation-provider(name="correo electrónico", rules="required|email", v-slot="{ errors }")
                    b-form-input(placeholder="Correo electrónico", name="email", type="email", v-model="email", :state="errors.length ? false : null")
                    b-form-invalid-feedback.text-sm.text-danger {{ errors[0] }}
                b-form-group(label="Contraseña")
                  validation-provider(name="contraseña", rules="required|min:1|max:50", v-slot="{ errors }")
                    b-form-input(placeholder="Contraseña", name="password", type="password", v-model="password", :state="errors.length ? false : null")
                    b-form-invalid-feedback.text-sm.text-danger {{ errors[0] }}
                .text-center
                  b-button(variant="primary", :disabled="invalid", type="submit") Iniciar sesión
</template> 

<script>
import { createNamespacedHelpers } from 'vuex';
const authController = createNamespacedHelpers('auth'); 

export default {
  data () {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    ...authController.mapActions(['login']),
    doLogin () {
      // do login
      this.login({
        user: { email: this.email, password: this.password },
        callback: res => {
          if (res.error) {
            this.$bvToast.toast(`Ocurrió un error. Por favor intenta de nuevo (${res.error})`, {
              title: 'Error',
              variant: 'danger'
            })
          } else {
            if (res.data && res.data.usuario && res.data.usuario.role == 'admin') {
              this.$bvToast.toast(`Hola de nuevo.`, {
                title: 'Bienvenido',
                variant: 'success'
              })

              setTimeout(() => {
                this.$cookies.set("email", this.email, "1y")

                this.$router.push({name: 'Dashboard'})
              }, 1000)
            } else {
              this.$bvToast.toast(`Solo los administradores tienen acceso a este dashboard.`, {
                title: 'Error',
                variant: 'warning'
              })

              setTimeout(() => {
                this.$router.push({name: 'Logout'})
              }, 500)
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  width: 100%;
  height: 100vh;
  position:relative;
}

.custom-shape-divider-bottom-1607648200 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1607648200 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 500px;
}

.custom-shape-divider-bottom-1607648200 .shape-fill {
    fill: #00A8C1;
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
    .custom-shape-divider-bottom-1607648200 svg {
        width: calc(100% + 1.3px);
        height: 500px;
    }
}
</style>