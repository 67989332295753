var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-container d-flex align-items-center"},[_c('div',{staticClass:"custom-shape-divider-bottom-1607648200"},[_c('svg',{attrs:{"data-name":"Layer 1","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1200 120","preserveAspectRatio":"none"}},[_c('path',{staticClass:"shape-fill",attrs:{"d":"M1200 120L0 16.48 0 0 1200 0 1200 120z"}})])]),_c('b-container',[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"p-5"},[_c('b-img',{staticClass:"mb-5",attrs:{"src":require("@/assets/logo.png"),"width":"200","center":""}}),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.doLogin)}}},[_c('b-form-group',{attrs:{"label":"Correo electrónico"}},[_c('validation-provider',{attrs:{"name":"correo electrónico","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Correo electrónico","name":"email","type":"email","state":errors.length ? false : null},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('b-form-invalid-feedback',{staticClass:"text-sm text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Contraseña"}},[_c('validation-provider',{attrs:{"name":"contraseña","rules":"required|min:1|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Contraseña","name":"password","type":"password","state":errors.length ? false : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-form-invalid-feedback',{staticClass:"text-sm text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid,"type":"submit"}},[_vm._v("Iniciar sesión")])],1)],1)]}}])})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }